<template>
    <div class="slider"
        v-if="slides.length"
    >
        <div class="slider__container">
            <SimpleCarousel
                :items-per-view="1"
                :enable-dots="true"
                :enable-buttons="false"
            >
                <div
                    class="slider__slide"
                    v-for="slide in slides">
                    <img
                        class="slider__slide __desktop"
                        :src="slide.desktop_preview"
                    />
                    <img
                        class="slider__slide __mobile"
                        :src="slide.mobile_preview"
                    />
                </div>
            </SimpleCarousel>
        </div>
    </div>
</template>

<script>
import SimpleCarousel from "vue2-simple-carousel";


export default {
    name: "Slider",
    components: {
        SimpleCarousel
    },
    props: {
        slides: {
            type: Array
        }
    },
}
</script>

<style lang="scss">;
.slider {
    display: flex;
    flex-direction: column;

    &__container {
        max-width: 1920px;
        margin: 0 auto;
        width: calc(100%);

        @include below($mobile) {
            width: calc(100% - 15px);
        }
    }

    &__slide {
        width: 100%;

        &.__desktop {
            display: block;

            @include below($mobile) {
                display: none;
            }
        }
        &.__mobile {
            display: none;

            @include below($mobile) {
                display: block;
            }
        }
    }

    .carousel {

        &__dots {
            position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
        }

        &__dot {
            border-radius: 0 !important;
            background: rgba(255, 255, 255, 0.3);
            border: none !important;
            width: 48px !important;
            height: 7px !important;
        }

        &__dot--active {
            background: #FF5050;
        }
    }
}
</style>