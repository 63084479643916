<template>
    <div class="autopark">
        <div class="autopark__container container">
            <div class="autopark__title">
                Автопарк и тарифы
            </div>
            <div class="autopark__toggles">
                <div v-for="el in typesFilters"
                     :class="[
                        'autopark__toggles-el',
                        {'__active': el.status}
                    ]"
                     @click="changeFilter(el)"
                >
                    {{ el.name }}
                </div>
            </div>
            <div class="autopark__models">
                <div class="autopark__models-list">
                    <div
                        v-for="car in carsFilter"
                        :class="[
                            'autopark__models-list-el',
                            {'__active': car.status },
                            {'__open': mobCarsMenuStatus }
                        ]"
                        @click="changeCar(car)"
                    >
                        <div class="autopark__models-list-el-name">
                            {{ car.info.brand + ' ' + car.info.model }}
                        </div>
                        <div class="autopark__models-list-el-drive"
                            v-if="car.info.is_driver_required"
                        >
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z" fill=""/>
                            </svg>
                            Только с водителем
                        </div>
                        <div class="autopark__models-list-el-price">
                            {{ car.info.price }} ₽ / сутки
                        </div>
                        <div class="autopark__models-list-mob-menu"
                            @click="mobCarsMenuStatus = !mobCarsMenuStatus"
                        >
                            {{ mobCarsMenuStatus ? 'Закрыть список' : 'Выбрать другое авто' }}
                        </div>
                    </div>
                </div>
                <div class="autopark__models-preview"
                     v-for="car in carsFilter"
                     v-if="car.status"
                >
                    <div class="autopark__models-preview-slider"
                        v-if="car.info.images.length"
                    >
                        <simple-carousel
                            :sidebar="false"
                            :items-per-view="1"
                            :enable-dots="true"
                            :enable-buttons="true"
                            :navigateBySlide="true"
                            @onPrevBySlide="true"
                        >
                            <div
                                v-for="slide in car.info.images"
                                class="autopark__models-preview-slider-slide"
                            >
                                <img
                                    class="autopark__models-preview-slider-el"
                                    :src="slide"
                                />
                            </div>
                        </simple-carousel>
                    </div>
                    <div class="autopark__models-preview-name">
                        {{ car.info.brand + ' ' + car.info.model }}
                    </div>
                    <div class="autopark__models-preview-info"
                        v-if="!car.info.is_driver_required"
                    >
                        <div class="autopark__models-preview-info-row">
                            <div class="autopark__models-preview-info-type">
                                Год выпуска
                            </div>
                            <div class="autopark__models-preview-info-value">
                                {{ selectedCars.info.year }}
                            </div>
                        </div>
                        <div class="autopark__models-preview-info-row">
                            <div class="autopark__models-preview-info-type">
                                Тип топлива
                            </div>
                            <div class="autopark__models-preview-info-value">
                                {{ selectedCars.info.fuel_type }}
                            </div>
                        </div>
                        <div class="autopark__models-preview-info-row">
                            <div class="autopark__models-preview-info-type">
                                Руль
                            </div>
                            <div class="autopark__models-preview-info-value">
                                {{ selectedCars.info.side === 'left' ? 'Левый' : 'Правый' }}
                            </div>
                        </div>
                        <div class="autopark__models-preview-info-row">
                            <div class="autopark__models-preview-info-type">
                                Расход топлива
                            </div>
                            <div class="autopark__models-preview-info-value">
                                {{ selectedCars.info.fuel_consumption }} л. / 100 км.
                            </div>
                        </div>
                        <div class="autopark__models-preview-info-row">
                            <div class="autopark__models-preview-info-type">
                                Тип коробки
                            </div>
                            <div class="autopark__models-preview-info-value">
                                {{ selectedCars.info.transmission }}
                            </div>
                        </div>
                        <div class="autopark__models-preview-info-row">
                            <div class="autopark__models-preview-info-type">
                                Цвет
                            </div>
                            <div class="autopark__models-preview-info-value">
                                {{ selectedCars.info.color }}
                            </div>
                        </div>
                        <div class="autopark__models-preview-info-row">
                            <div class="autopark__models-preview-info-type">
                                Мощность двигателя
                            </div>
                            <div class="autopark__models-preview-info-value">
                                {{ selectedCars.info.engine_capacity }}
                            </div>
                        </div>
                    </div>
                    <div class="autopark__models-preview-info-bottom">
                        <div class="autopark__models-preview-info-price">
                            {{ selectedCars.info.price }} ₽ / сутки
                        </div>
                        <div class="autopark__models-preview-info-btn"
                            @click="$emit('openPopupRent', selectedCars.info)"
                        >
                            Арендовать
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import star from '../assets/images/star.svg';
import previewAuto from '../assets/images/slider/preview_auto.png';

import SimpleCarousel from "vue2-simple-carousel";

export default {
    name: "Autopark",
    components: {
        SimpleCarousel
    },
    props: {
        autopark: {
        }
    },
    data() {
        return {
            images: {
                star,
                previewAuto
            },
            typesFilters: this.formattedTypes(),
            carsFilter: {},
            selectedCars: '',
            mobCarsMenuStatus: false,
            currentFilter: 'Все'
        }
    },
    methods: {
        formattedTypes() {
            let list = []
            this.autopark.types.forEach(e => {
                list.push({name: e, status: false})
            })
            list[0].status = true

            return list
        },
        formatterCars(list) {
            let result = []
            list.forEach(e => {
                result.push({info: e, status: false, filter: false})
            })

            if (result.length) result[0].status = true;
            if (result.length) this.selectedCars = result[0];

            return result
        },
        changeFilter(el) {
            this.typesFilters.forEach(e => e.status = false);

            if (el.name !== 'Все') {
                this.carsFilter = this.formatterCars(this.autopark.list).filter(e => e.info.quality === el.name);

                if (this.carsFilter.length) this.carsFilter[0].status = true;
                if (this.carsFilter.length) this.selectedCars = this.carsFilter[0];
            }
            else {
                this.carsFilter = this.formatterCars(this.autopark.list);
            }

            el.status = true;
        },
        changeCar(car) {
            if (car.status === true) return
            this.carsFilter.forEach(e => e.status = false);
            this.selectedCars = car;
            car.status = true;
            this.mobCarsMenuStatus = false;
        },
    },
    mounted() {
        this.carsFilter = this.formatterCars(this.autopark.list);
    },
    watch: {
        autopark: {
            handler() {
                this.carsFilter = this.formatterCars(this.autopark.list);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">;
.autopark {
    padding: 55px 0 35px;

    &__title {
        font-weight: 700;
        font-size: 32px;

        @include below($mobile) {
            text-align: center;
            font-size: 20px;
        }
    }

    &__toggles {
        display: flex;
        margin-top: 28px;

        @include below($mobile) {
            justify-content: center;
            flex-wrap: wrap;
        }

        &-el {
            position: relative;
            color: #8B8B8B;
            font-weight: 400;
            font-size: 20px;
            cursor: pointer;
            user-select: none;

            @include below($mobile) {
                font-size: 16px;
            }

            &:not(:first-child) {
                margin-left: 40px;

                @include below($mobile) {
                    margin-left: 30px;
                }

                &:before {
                    position: absolute;
                    content: '/';
                    left: -22px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: default;
                    color: #888;

                    @include below($mobile) {
                        left: -18px;
                    }
                }
            }

            &.__active {
                color: #212050;
                font-weight: 700;
            }
        }
    }

    &__models {
        display: flex;
        justify-content: space-between;

        @include below($mobile) {
            flex-direction: column;
        }

        &-list,
        &-preview {
            overflow: hidden;
            width: calc(100% / 2 - 16px);

            &-slider {

                &-slide {
                    width: 100%
                }

                .ColorfullGalleryWrapper {
                    min-height: 350px;

                    @include below($mobile) {
                        min-height: 300px;
                    }

                    @include below(375px) {
                        min-height: 250px;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    max-height: 450px;
                    object-fit: cover;
                    object-position: 50% 50%;

                    @include below($tablet) {
                        max-height: 350px;
                    }

                    @include below($mobile) {
                        max-height: 250px;
                    }
                }
            }

            @include below($mobile) {
                width: calc(100%);
            }
        }

        &-list {
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            z-index: 1;

            @include below($mobile) {
                margin-top: 25px;
                padding-bottom: 30px;
                background: #fff;
            }

            &-el {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding: 12px 24px;
                background: #F8F8F8;
                color: #2B2B2B;
                transition: .15s ease-in-out;
                cursor: pointer;

                @include below($mobile) {
                    display: none;
                    padding: 12px 16px;
                    flex-wrap: wrap;
                }

                &:not(:first-child) {
                    margin-top: 16px;

                    @include below($mobile) {
                        margin-top: 4px;
                    }
                }

                &.__active {
                    background: #212050;
                    color: #fff;

                    @include below($mobile) {
                        display: flex;
                        order: -1;
                    }

                    .autopark__models-list-el-drive svg {
                        fill: #fff;
                    }

                    &:after,
                    &:before {
                        background: #fff;

                        @include below($mobile) {
                            display: flex !important;
                        }
                    }
                }

                &.__open {
                    @include below($mobile) {
                        display: flex;

                        &:after,
                        &:before {
                            display: none;
                        }
                    }
                }

                &:after,
                &:before {
                    display: none;
                    position: absolute;
                    content: '';
                    bottom: 16px;
                    transform: translateY(-50%);
                    width: 10px;
                    height: 2px;
                    background: #212050;
                    transition: .15s ease-in;

                    @include below($mobile) {
                        display: block;
                    }
                }

                &:after {
                    right: 19px;
                    transform: translateY(0px) rotate(135deg);
                }

                &:before {
                    right: 25px;
                    transform: translateY(0px) rotate(-135deg);
                }

                &-name {
                    font-weight: 400;
                    font-size: 18px;
                    order: 0;

                    @include below($mobile) {
                        width: 100%
                    }
                }

                &-drive {
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    margin-left: 20px;

                    @include below($mobile) {
                        align-items: flex-end;
                        width: calc(50% - 20px);
                        font-size: 11px;
                        order: 2;
                    }

                    svg {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                        left: -20px;
                        top: 5px;
                        fill: #000;

                        @include below($mobile) {
                            top: 16px;
                        }
                    }
                }

                &-price {
                    position: relative;
                    font-weight: 700;
                    font-size: 18px;
                    margin-right: 30px;

                    @include below($mobile) {
                        width: calc(50% - 0px);
                        margin-top: 10px;
                        margin-right: 0;
                        font-size: 16px;
                        order: 1;
                    }

                    &:after,
                    &:before {
                        position: absolute;
                        content: '';
                        right: -25px;
                        transform: translateY(-50%);
                        width: 10px;
                        height: 2px;
                        background: #212050;

                        @include below($mobile) {
                            display: none;
                        }
                    }

                    &:after {
                        bottom: 17px;
                        transform: translateY(0px) rotate(45deg);
                    }

                    &:before {
                        bottom: 10px;
                        transform: translateY(0px) rotate(-45deg);
                    }
                }
            }

            &-mob-menu {
                display: none;
                width: 100%;
                order: 4;
                margin-top: 12px;
                font-size: 12px;

                @include below($mobile) {
                    display: block;
                }
            }
        }

        &-preview {
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            padding: 24px;
            background: #F8F8F8;

            @include below($mobile) {
                padding: 14px;
                margin-top: 10px;
            }

            &-name {
                margin-top: 14px;
                font-weight: 700;
                font-size: 18px;
            }

            &-info {
                display: flex;
                flex-direction: column;
                margin: 14px 0;
                height: 100%;

                &-row {
                    display: flex;
                    justify-content: space-between;

                    &:not(:first-child) {
                        margin-top: 16px;
                    }
                }

                &-type {
                    font-weight: 400;
                    font-size: 16px;
                    color: #8B8B8B;
                }

                &-value {
                    font-weight: 400;
                    font-size: 16px;
                }

                &-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: auto 0 0;

                    @include below($mobile) {
                        flex-direction: column;
                    }
                }

                &-price {
                    font-weight: 700;
                    font-size: 18px;
                }

                &-btn {
                    padding: 16px 32px;
                    font-weight: 700;
                    font-size: 16px;
                    background: $brandColor;
                    transition: .15s ease-in;
                    color: #fff;
                    cursor: pointer;

                    @include below($mobile) {
                        width: 100%;
                        margin-top: 20px;
                        text-align: center;
                    }

                    &:hover {
                        background: $brandColorWhite;
                    }
                }
            }
        }
    }

    .carousel__dots {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 25px;
        bottom: 50%;
        left: 95%;
        transform: translateY(50%) rotate(0deg);

        @include below($mobile) {
            left: 92%;
        }
    }

    .carousel__dot {
        width: 6px !important;
        height: 10px !important;
        border-radius: 8px !important;
        margin-bottom: 6px;
        margin-right: 0 !important;
        background: rgba(255, 255, 255, 0.44);
        border: none;
        transition: height .3s ease-in-out;
        opacity: 1 !important;
    }

    .carousel__dot--active {
        height: 80px !important;
        background: #ffffff;
    }

    .carousel__button {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        background: #00000000;
        border: none !important;
        outline: none !important;
        cursor: pointer;
        opacity: 0;

        @include below($tablet) {
            display: none;
        }

        &:after,
        &:before {
            position: absolute;
            content: '';
            right: calc(50%);
            transform: translateY(-50%);
            width: 18px;
            height: 2px;
            background: #fff;
            transition: .15s ease-in-out;

            @include below($mobile) {
                display: block;
            }
        }

        &:after {
            bottom: calc(50% + 6px);
            transform: translateY(-50%) rotate(45deg);
        }

        &:before {
            bottom: calc(50% - 6px);
            transform: translateY(-50%) rotate(-45deg);
        }

        span {
            display: none;
        }

        &:hover {
            transition: .2s ease-in-out;
            background: #00000044;
            opacity: 1;
        }

        &--prev {
            &:after {
                bottom: calc(50% + 6px);
                transform: translateY(-50%) rotate(-45deg);
            }

            &:before {
                bottom: calc(50% - 6px);
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }
}
</style>