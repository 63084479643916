<template>
    <div class="advantages">
        <div class="advantages__container container">
            <div class="advantages__list">
                <div class="advantages__list-el"
                     v-for="adv in advantages"
                     :key="adv.name"
                >
                    <div class="advantages__list-el-ico">
                        <img :src="adv.preview"/>
                    </div>
                    <div class="advantages__list-el-name">
                        {{ adv.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Advantages",
    props: {
        advantages: {
            type: Array
        }
    }

}
</script>

<style lang="scss" scoped>;
.advantages {
    display: flex;

    &__container {
        background: url('../assets/images/adv.png') center/100% no-repeat;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        margin: 110px 50px;

        @include below($mobile) {
            flex-direction: column;
            margin: 40px 0;
            text-align: center;
        }

        &-el {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0 10px;

            &:not(:first-child) {
                @include below($mobile) {
                    margin-top: 30px;
                }
            }

            &-ico {
                width: 80px;
                height: 76px;
            }

            &-name {
                margin-top: 25px;
                font-weight: 700;
                font-size: 16px;

                @include below($mobile) {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>