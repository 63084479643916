<template>
    <div class="feedback__wrapper">
        <div class="feedback">
            <img
                class="feedback__bg"
                :src="images.feedbackbg"
            />
            <div class="feedback__container container">
                <div class="feedback__left-side">
                    <div class="feedback__left-side-title">
                        Обратная связь
                    </div>
                </div>
                <div class="feedback__right-side">
                    <div class="feedback__right-side-text">
                        Остались вопросы? Оставьте номер телефона и мы всё подскажем
                    </div>
                    <div class="feedback__right-side-input __name">
                        <input
                            type="text"
                            placeholder="Введите ваше имя"
                            v-model="formData.name"
                        >
                    </div>
                    <div class="feedback__right-side-input __phone">
                        <input
                            type="text"
                            placeholder="Введите ваш номер"
                            v-model="formData.phone"
                            id="phone"
                        >
                        <span v-if="this.showErrors && this.formData.phone === ''"
                              class="feedback__error"
                        >
                    * Обязательное поле
                </span>
                    </div>
                    <div class="feedback__right-side-submit"
                         @click="sendForm"
                    >
                        Заказать звонок
                    </div>
                    <div
                        class="feedback__success"
                        v-if="success"
                    >
                        Ваша заявка успешно оформлена!
                    </div>
                    <span class="feedback__right-side-disclaimer">
                Нажимая кнопку "Заказать звонок", Вы соглашаетесь с <span @click="openPolitics">политикой конфиденциальности</span>
            </span>
                </div>
            </div>
        </div>
        <div
            v-if="formMessage !== '' && this.showPopup"
            class="feedback__popup">
            <span class="feedback__popup-overlay"
                  @click="showPopup = false"
            ></span>
            <div class="feedback__popup-content">
                <div class="feedback__close-btn"
                     @click="showPopup = false"
                >
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                        <g>
                            <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512
                                512,452.922 315.076,256 		"/>
                        </g>
                    </g>
                </svg>
                </div>
                <div v-if="!formStatus"
                     class="feedback__popup-image"
                >
                    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/>
                        <g data-name="1" id="_1">
                            <path
                                d="M257,461.46c-114,0-206.73-92.74-206.73-206.73S143,48,257,48s206.73,92.74,206.73,206.73S371,461.46,257,461.46ZM257,78C159.55,78,80.27,157.28,80.27,254.73S159.55,431.46,257,431.46s176.73-79.28,176.73-176.73S354.45,78,257,78Z"/>
                            <path
                                d="M342.92,358a15,15,0,0,1-10.61-4.39L160.47,181.76a15,15,0,1,1,21.21-21.21L353.53,332.4A15,15,0,0,1,342.92,358Z"/>
                            <path
                                d="M171.07,358a15,15,0,0,1-10.6-25.6L332.31,160.55a15,15,0,0,1,21.22,21.21L181.68,353.61A15,15,0,0,1,171.07,358Z"/>
                        </g>
                    </svg>
                </div>
                <div v-else
                     class="feedback__popup-image"
                >
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="611.994px" height="611.994px" viewBox="0 0 611.994 611.994" style="enable-background:new 0 0 611.994 611.994;"
                         xml:space="preserve">
                        <g>
                            <g>
                                <g>
                                    <path d="M248.172,423.918l-89.545-89.534c-5.637-5.637-5.637-14.778,0-20.416c5.643-5.644,14.78-5.644,20.417,0l69.128,69.122
                                        l184.796-184.802c5.644-5.643,14.78-5.643,20.417,0c5.644,5.637,5.644,14.78,0,20.417L248.172,423.918z"/>
                                    <path d="M306.031,611.994v-14.438l-0.022,14.438C137.286,611.994,0.012,474.726,0,306.003C0,137.274,137.274,0,305.997,0
                                        c168.729,0,305.997,137.274,305.997,305.997C612,474.726,474.743,611.994,306.031,611.994z M305.997,28.878
                                        c-152.805,0-277.119,124.314-277.119,277.119C28.89,458.796,153.209,583.11,306.009,583.11h0.022
                                        c152.788,0,277.091-124.314,277.091-277.113C583.122,153.192,458.802,28.878,305.997,28.878z"/>
                                </g>
                            </g>
                        </g>
                        </svg>
                </div>
                {{ formMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import feedbackbg from '../assets/images/feedbackbg.jpg';
import axios from "axios";
import Inputmask from "inputmask";

export default {
    name: "Feedback",
    data() {
        return {
            images: {
                feedbackbg
            },
            success: false,
            formData: {
                name: '',
                phone: ''
            },
            formValid: false,
            showErrors: false,
            formMessage: 'Что то пошло не так. Перезагрузите страницу и попробуйте еще раз.',
            formStatus: false,
            showPopup: false
        }
    },
    methods: {
        openPolitics() {
            this.$emit('openPopupPolitics')
        },
        sendForm() {
            if (this.formData.phone === '') {
                this.formValid = false;
                this.showErrors = true;
            } else {
                this.formValid = true;
                let token = '';

                document.cookie ? token = document.cookie.split('=')[1] : '';

                axios({
                    url: this.baseUrl + '/api/orders/',
                    method: 'post',
                    data: this.formData,
                    headers: {
                        'content-type': 'application/json',
                        'data-type': 'json',
                        'x-requested-with': 'XMLHttpRequest',
                        'X-CSRFToken': token
                    },
                })
                    .then(response => response.data)
                    .then(data => {
                        this.showErrors = false;
                        this.formStatus = true;
                        this.formData.name = '';
                        this.formData.phone = '';
                        this.formMessage = 'Спасибо за Вашу заявку, менеджер свяжется с Вами в ближайшее время.'
                    })
                    .catch(error => {
                        this.formStatus = false;
                        this.formMessage = 'Что то пошло не так. Перезагрузите страницу и попробуйте еще раз.';
                    })
                    .finally(() => {
                        this.showPopup = true
                    })
                ;
            }
        }
    },
    mounted() {
        var im = new Inputmask("+7 (999) 999-99-99");
        im.mask(document.getElementById('phone'));
    },
    computed: {
        baseUrl: () => window.baseUrl.VUE_APP_WEBSITE_URL
    }
}
</script>

<style scoped lang="scss">;
.feedback {
    position: relative;
    display: flex;
    padding: 32px 0;
    color: #fff;

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        object-fit: cover;
        object-position: 50% 50%;
    }

    &__container {
        display: flex;
        z-index: 2;

        @include below($mobile) {
            flex-direction: column;
            align-items: center;
            padding: 0 16px;
        }
    }

    &__left-side {
        width: 55%;

        &-title {
            margin-top: 15px;
            font-weight: 700;
            font-size: 32px;

            @include below($mobile) {
                margin-bottom: 15px;
                font-size: 20px;
                text-align: center;
            }
        }
    }

    &__right-side {
        width: 570px;
        display: flex;
        flex-direction: column;

        @include below($mobile) {
            width: 100%;
        }

        &-input {
            position: relative;
            margin-top: 24px;
            background: #fff;

            input {
                width: 100%;
                padding: 16px;
            }
        }

        &-submit {
            width: 100%;
            margin-top: 24px;
            padding: 16px 0;
            background: $brandColor;
            text-align: center;
            transition: .15s ease-in;
            cursor: pointer;

            &:hover {
                background: $brandColorWhite;
            }
        }

        &-disclaimer {
            margin-top: 12px;
            font-size: 12px;

            span {
                color: #ddd;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    &__close-btn {
        position: absolute;
        width: 15px;
        height: 15px;
        right: 14px;
        top: 14px;
        cursor: pointer;
    }

    &__success {
        margin-top: 10px;
        font-size: 20px;
        color: #56ff5e;
    }

    &__error {
        position: absolute;
        bottom: -20px;
        left: 0;
        color: #ff0000;
    }

    &__popup {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;

        &-content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 18px;
            max-width: 350px;
            height: auto;
            background: #fff;
            box-shadow: 4px 4px 8px #dddddd24, -4px -4px 8px #2222221f;
            padding: 85px 50px;
            z-index: 1;
        }

        &-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #00000099;
            z-index: 0;
        }

        &-image {
            width: 125px;
            height: 125px;
            margin-bottom: 25px;

            svg {
                width: 125px;
                height: 125px;
            }
        }
    }
}
</style>