<template>
    <div class="preview">
        <div class="preview__container container">
            <div class="preview__left-side">
                <div class="preview__left-side-text">
                    {{ text }}
                </div>
                <div class="preview__left-side-title">
                    {{ title }}
                </div>
                <ul class="preview__left-side-list">
                    <li
                        v-for="(el, i) in list"
                        :key="i"
                    >{{ el }}
                    </li>
                </ul>
                <a class="preview__left-side-btn"
                   v-scroll-to="'#catalog'"
                >
                    Подобрать авто
                </a>
            </div>
            <div class="preview__right-side">
                <img :src="bgImage"/>
            </div>
        </div>
    </div>
</template>

<script>
import auto from '../assets/images/auto.jpg';

export default {
    name: "Preview",
    props: {
        list: {
            type: Array
        },
        text: {
            type: String
        },
        title: {
            type: String
        },
        bgImage: {
        }
    },
    data() {
        return {
            images: {
                auto,
            }
        }
    }
}
</script>

<style lang="scss" scoped>;
.preview {
    display: flex;
    position: relative;
    font-family: 'Manrope', sans-serif;
    overflow: hidden;
    margin-top: 50px;

    @include below($mobile) {
        padding: 40px 0;
    }

    &:before {
        display: none;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: url('../assets/images/auto.jpg') center/cover no-repeat;
        filter: blur(8px);

        @include below($mobile) {
            display: block;
        }
    }

    &:after {
        display: none;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #00000099;
        z-index: 1;

        @include below($mobile) {
            display: block;
        }
    }

    &__container {
        display: flex;
        z-index: 2;
    }

    &__left-side,
    &__right-side {
        display: flex;
        width: 50%;
        min-height: 775px;

        @include below($mobile) {
            min-height: auto;
        }
    }

    &__right-side {
        overflow: hidden;

        @include below($mobile) {
            display: none;
        }

        img {
            position: absolute;
            max-height: 940px;
            max-width: calc(1920px / 2);
            height: 100%;
            object-fit: cover;
        }
    }

    &__left-side {
        display: flex;
        flex-direction: column;
        margin-top: 125px;

        @include below($mobile) {
            width: 100%;
            margin: 0 10px;
            height: auto;
            color: #fff;
        }

        &-text {
            margin-bottom: 16px;
            font-weight: 400;
            font-size: 20px;

            @include below($mobile) {
                font-size: 16px;
            }
        }

        &-title {
            margin-bottom: 48px;
            font-weight: 700;
            font-size: 48px;
            line-height: 140%;

            @include below($mobile) {
                margin-bottom: 24px;
                font-size: 24px;
            }
        }

        &-list {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                bottom: -48px;
                width: 205px;
                height: 4px;
                background: $brandColor;

                @include below($mobile) {
                    display: none;
                }

            }

            li {
                list-style-type: disc;
                margin-left: 20px;
                padding: 8px 0;

                @include below($mobile) {
                    font-size: 14px;
                }

                &::marker {
                    color: $brandColor;

                    @include below($mobile) {
                        color: #fff;
                    }
                }
            }
        }

        &-btn {
            margin: 100px auto 0 0;
            padding: 16px 40px;
            background: $brandColor;
            box-shadow: 12px 11px 40px rgba(32, 31, 105, 0.24);
            color: #fff;
            font-weight: bold;
            font-size: 16px;
            transition: .15s ease-out;
            cursor: pointer;

            @include below($mobile) {
                margin: 25px 0 0 0;
                text-align: center;
            }

            &:hover {
                box-shadow: 12px 11px 40px rgba(32, 31, 105, 0.54);
            }
        }
    }
}

</style>