<template>
    <div id="app">
        <Header
            :menu-elements="menuElements"
            :contacts="contacts"
        />
        <Preview
            :text="preview.text"
            :title="preview.title"
            :list="preview.list"
            :bg-image="preview.image"
        />

        <span id="advantage"></span>
        <Advantages
            :advantages="advantages"
        />
        <Slider
            v-if="slides"
            :slides="slides"
        />

        <span id="catalog"></span>
        <Autopark
            :autopark="autopark"
            @openPopupRent="openRentPopup"
        />

        <span id="about"></span>
        <About
            :text="contacts.about_text"
            :contacts="contacts"
        />

        <span id="faq"></span>
        <Conditions
            :conditions="conditions"
        />

        <span id="feedback"></span>
        <Feedback
            @openPopupPolitics="politicsModalStatus = true"
        />

        <Footer
            :menu-elements="menuElements"
            :contacts="contacts"
            @openPopupPolitics="politicsModalStatus = true"
        />

        <PoliticsModal
            :status="politicsModalStatus"
            :text="contacts.policy"
            @closePopupPolitics="politicsModalStatus = false"
            @openPopupPolitics="politicsModalStatus = true"
        />
        <RentModal
            :status="rentModalStatus"
            :info="rentModalSelectedCar"
            @closePopupRent="rentModalStatus = false"
            @openPopupRent="rentModalStatus = true"
        ></RentModal>
        <v-scroll-to-top></v-scroll-to-top>
    </div>
</template>

<script>
// images
import auto from '@/assets/images/adv/auto.svg';
import man from '@/assets/images/adv/man.svg';
import star from '@/assets/images/adv/star.svg';
import time from '@/assets/images/adv/time.svg';

// components
import Header from '@/components/Header.vue';
import Preview from '@/components/Preview.vue';
import Advantages from "@/components/Advantages";
import Slider from "@/components/Slider";
import Autopark from "@/components/Autopark";
import About from "@/components/About";
import Conditions from "@/components/Conditions";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import PoliticsModal from "@/components/PoliticsModal";
import RentModal from "@/components/RentModal";

import axios from 'axios';

export default {
    name: 'App',
    components: {
        RentModal,
        PoliticsModal,
        Feedback,
        Conditions,
        About,
        Slider,
        Autopark,
        Advantages,
        Header,
        Preview,
        Footer
    },
    data() {
        return {
            menuElements: [
                {
                    name: 'Каталог авто',
                    link: '#catalog'
                },
                {
                    name: 'Преимущества',
                    link: '#advantage'
                },
                {
                    name: 'О нас',
                    link: '#about'
                },
                {
                    name: 'F.A.Q',
                    link: '#faq'
                },
                {
                    name: 'Обратная связь',
                    link: '#feedback'
                },
            ],
            contacts: {
                phone: '+79999999999',
                address: 'г. Красноярск, ул. Карла Маркса, 52',
                whatsApp: '',
                telegram: '',
                viber: ''
            },
            preview: {
                text: 'Сервис аренды автомобилей «AVTOKOMFORT»',
                title: 'Подберем лучшее авто для ваших предпочтений',
                list: [
                    'Автомобили премиум, комфорт и бизнес-класса с водителем и без',
                    'Для поездок по городу и в другие регионы',
                    'На любые цели и мероприятия'
                ],
                image: ''
            },
            advantages: [
                {
                    title: 'Автомобили любого класса',
                    preview: auto
                },
                {
                    title: 'Вежливые и пунктуальные водители',
                    preview: man
                },
                {
                    title: 'Работаем уже более двух лет',
                    preview: time
                },
                {
                    title: 'Идеальное состояние всех автомобилей',
                    preview: star
                }
            ],
            slides: [],
            autopark: {
                types: ['Все', 'Минивэн', 'Комфорт', 'Бизнес', 'Премиум'],
                list: []
            },
            conditions: [
                'Мы работаем с физическими и юридическими лицами⁣⁣',
                'Заказать автомобиль вы можете по телефону или в мессенджере. Время и место подачи автомобиля оговаривается индивидуально',
                'Мы подписываем договор аренды автомобиля',
                'Оплата производится перед поездкой. Платеж можно совершить наличными или перечислить на расчетный счет',
                'Требования к водителю: от 23 лет со стажем вождения не менее 3-х лет',
                'Автомобиль подается чистый и с полным баком топлива',
                'Условия возврата: вернуть в исходном состоянии или оплатить стоимость автомойки и дозаправки'
            ],
            politicsModalStatus: false,
            rentModalStatus: false,
            rentModalSelectedCar: {}
        }
    },
    methods: {
        getCars() {
            axios({
                url: this.baseUrl + '/api/cars/',
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }).then(response => {
                this.autopark.list = response.data;
            })
        },
        getAdvantages() {
            axios({
                url: this.baseUrl + '/api/advantages/',
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }).then(response => {
                this.advantages = response.data
            })
        },
        getConditions() {
            axios({
                url: this.baseUrl + '/api/conditions/',
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }).then(response => {
                this.conditions = response.data
            })
        },
        getSmartbanners() {
            axios({
                url: this.baseUrl + '/api/smartbanners/',
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }).then(response => {
                this.slides = response.data
            })
        },
        getWebsiteInfo() {
            axios({
                url: this.baseUrl + '/api/website/1/',
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }).then(response => {
                this.contacts = response.data;
                this.preview.image = response.data.main_preview;
            })
        },
        openRentPopup(car) {
            this.rentModalStatus = true;
            this.rentModalSelectedCar = car;
        }
    },
    mounted() {
        this.getAdvantages();
        this.getCars();
        this.getConditions();
        this.getSmartbanners();
        this.getWebsiteInfo();
    },
    computed: {
        baseUrl: () => window.baseUrl.VUE_APP_WEBSITE_URL
    }
}
</script>

<style lang="scss">;

body {
    overflow-y: scroll;
}
#app {
    position: relative;
    font-family: 'Manrope', sans-serif;
}
#icon-container {
    z-index: 10;
}

</style>
