<template>
    <div :class="[
        'politics',
        {'__open': status }
    ]">
        <span class="politics__overlay" @click="closePopup">
        </span>
        <div class="politics__box">
            <div class="politics__box-text"
                v-html="text"
            >
            </div>
            <div class="politics__close-btn"
                 @click="closePopup"
            >
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                        <g>
                            <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512
                                512,452.922 315.076,256 		"/>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PoliticsModal",
    props: {
        status: {
            default: true
        },
        text: {}
    },
    methods: {
        closePopup() {
            this.$emit('closePopupPolitics')
        },
        openPopup() {
            this.$emit('openPopup')
        }
    },
    watch: {
        status: function() {
            this.status ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''
        }
    }
}
</script>

<style lang="scss">;

.politics {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;

    &.__open {
        display: block;
    }

    &__box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 750px;
        height: 450px;
        border-radius: 4px;
        background: #fff;
        z-index: 13;

        @include below($mobile) {
            width: calc(100% - 15px);
        }

        &-text {
            min-width: 245px;
            height: 380px;
            margin: 40px 35px;
            overflow-y: scroll;

            @include below($mobile) {
                width: calc(100% - 70px);
            }

            h1 {
                font-size: 24px;

                @include below($mobile) {
                    font-size: 18px;
                }
            }
        }
    }

    &__close-btn {
        position: fixed;
        width: 15px;
        height: 15px;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000099;
        z-index: 12;
        cursor: pointer;
    }
}

</style>