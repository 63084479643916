<template>
    <div class="conditions">
        <div class="conditions__container container">
            <img
                class="conditions__image"
                :src="images.hand"/>
            <div class="conditions__title">
                Условия аренды
            </div>
            <ul class="conditions__list">
                <li class="conditions__list-el"
                    v-for="(condition,ind) in conditions"
                    :key="ind"
                >
                    <h3 v-html="condition.title"></h3>
                    <div v-html="condition.description"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import hand from '../assets/images/hand.png';

export default {
    name: "Conditions",
    props: {
        conditions: {
            type: Array
        }
    },
    data() {
        return {
            images: {
                hand
            }
        }
    }
}
</script>

<style lang="scss">;

.conditions {
    display: flex;

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 56px 0 56px 120px;

        @include below($mobile) {
            padding: 24px 16px 56px;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 32px;

        @include below($mobile) {
            text-align: center;
            font-size: 20px;
        }
    }

    &__list {
        margin-top: 24px;

        @include below($mobile) {
            margin-top: 48px;
        }

        &-el {
            position: relative;
            margin-left: 16px;

            h3 {
                font-weight: bold;
                margin-bottom: 2px;
            }

            li  {
                padding-left: 10px;
                margin-bottom: 5px;
            }

            &:not(:first-child) {
                margin-top: 16px;
            }

            &::before {
                position: absolute;
                content: '';
                left: -16px;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                height: 100%;
                background: #ED7833;
            }
        }
    }

    &__image {
        position: absolute;
        left: -300px;
        width: 350px;
        height: 350px;

        @include below($mobile) {
            top: 10px;
            left: -0px;
            width: 90px;
            height: 90px;
        }
    }
}

</style>