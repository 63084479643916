<template>
    <div class="about">
        <div class="about__container container">
            <div class="about__left-side">
                <img class="about__left-side-mobile-bg"
                     :src="images.mob_bg" />
                <div class="about__left-side-title">
                    О нас
                </div>
                <div class="about__left-side-text"
                    v-html="text"
                >
                </div>
                <div class="about__left-side-contacts">
                    <div class="about__left-side-contacts-title"
                        v-if="contacts.viber || contacts.whatsApp || contacts.telegram"
                    >
                        Связаться с нами:
                    </div>
                    <div class="about__left-side-contacts-socials">
                        <a
                            v-if="contacts.viber"
                            class="about__left-side-contacts-socials-el"
                            :href="'viber://chat/?number=%2B' + contacts.viber">
                            <img :src="images.viber" />
                        </a>
                        <a
                            v-if="contacts.telegram"
                            class="about__left-side-contacts-socials-el"
                            :href="'tg://resolve?domain=' + contacts.telegram">
                            <img :src="images.telegram" />
                        </a>
                        <a
                            v-if="contacts.whatsapp"
                            class="about__left-side-contacts-socials-el"
                            :href="'https://wa.me/' + contacts.whatsapp">
                            <img :src="images.whatsapp" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="about__right-side">
                <img :src="images.bg" />
            </div>
        </div>
    </div>
</template>

<script>
import bg from '../assets/images/about_bg.png';
import mob_bg from '../assets/images/aboutMobileBg.png';
import telegram from '../assets/images/Telegram.svg';
import viber from '../assets/images/Viber.svg';
import whatsapp from '../assets/images/WhatsApp.svg';

export default {
    name: "about",
    props: {
        text: {},
        contacts: {}
    },
    data() {
        return {
            images: {
                bg,
                mob_bg,
                telegram,
                viber,
                whatsapp
            }
        }
    }
}
</script>

<style lang="scss" scoped>;
.about {
    display: flex;

    &__container {
        display: flex;
        justify-content: space-between;

        @include below($mobile) {
            width: 100%;
        }
    }

    &__left-side,
    &__right-side {
    }

    &__right-side {
        width: 55%;

        @include below($mobile) {
            display: none;
        }

        img {
            height: 100%;
            object-fit: cover;
            object-position: 0% 50%;
            font-family: 'object-fit: cover; object-position: 55% 50%;';
        }
    }

    &__left-side {
        position: relative;
        display: flex;
        width: 45%;
        flex-direction: column;
        padding: 40px 0 55px;

        @include below($mobile) {
            max-height: none;
            padding: 24px 16px 32px;
            width: 100%;
            color: #fff;
        }

        &-mobile-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            @include above($mobile) {
                display: none;
            }
        }

        &-title {
            font-weight: 700;
            font-size: 32px;

            @include below($mobile) {
                text-align: center;
                font-size: 20px;
            }
        }

        &-text {
            margin-top: 24px;
            font-size: 16px;
            line-height: 22px;

            p {
                &:not(:first-child) {
                    margin-top: 16px;
                }
            }
        }

        &-contacts {
            display: flex;
            flex-direction: column;
            margin-top: 40px;

            @include below($mobile) {
                align-items: center;
            }

            &-socials {
                display: flex;
                margin-top: 15px;

                &-el {
                    width: 32px;
                    height: 32px;
                    &:not(:first-child) {
                        margin-left: 24px;
                    }
                }
            }
        }
    }
}

</style>