<template>
    <div :class="[
        'rent-popup',
        {'__open': status }
    ]">
        <span class="rent-popup__overlay" @click="closePopup">
        </span>
        <div class="rent-popup__box">
            <div class="rent-popup__content"
                v-if="!successForm"
            >
                <div class="rent-popup__close-btn"
                     @click="closePopup"
                >
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                        <g>
                            <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512
                                512,452.922 315.076,256 		"/>
                        </g>
                    </g>
                </svg>
                </div>
                <div class="rent-popup__title">
                    Аренда автомобиля
                </div>
                <div class="rent-popup__description">
                    Ваши контактные данные
                </div>
                <div class="rent-popup__field">
                    <input type="text"
                           class="rent-popup__input"
                           placeholder="ФИО"
                           v-model="formData.name"
                    >
                </div>
                <div class="rent-popup__field">
                    <input type="text"
                           class="rent-popup__input"
                           placeholder="Номер телефона"
                           v-model="formData.phone"
                           id="phoneModal"
                    >
                    <span v-if="this.showErrors && this.formData.phone === ''"
                          class="rent-popup__error"
                    >
                        * Обязательное поле
                    </span>
                </div>
                <div class="rent-popup__field __selected-auto">
                    <div class="rent-popup__field-placeholder">
                        Выбранный автомобиль
                    </div>
                    <div class="rent-popup__field-value">
                        {{ info.brand + ' ' + info.model }}
                    </div>
                    <div
                        v-if="!info.is_driver_required"
                        @click="infoOpen = !infoOpen"
                        :class="[
                         'rent-popup__field-select',
                         {'__open': infoOpen}
                     ]"
                    >
                        Показать характеристики
                    </div>
                </div>
                <div
                    :class="[
                    'rent-popup__info',
                    {'__open': infoOpen}
                ]"
                    v-if="!info.is_driver_required"
                >
                    <div class="rent-popup__info-row">
                        <div class="rent-popup__info-type">
                            Год выпуска
                        </div>
                        <div class="rent-popup__info-value">
                            {{ info.year }}
                        </div>
                    </div>
                    <div class="rent-popup__info-row">
                        <div class="rent-popup__info-type">
                            Тип топлива
                        </div>
                        <div class="rent-popup__info-value">
                            {{ info.fuel_type }}
                        </div>
                    </div>
                    <div class="rent-popup__info-row">
                        <div class="rent-popup__info-type">
                            Руль
                        </div>
                        <div class="rent-popup__info-value">
                            {{ info.side === 'left' ? 'Левый' : 'Правый' }}
                        </div>
                    </div>
                    <div class="rent-popup__info-row">
                        <div class="rent-popup__info-type">
                            Расход топлива
                        </div>
                        <div class="rent-popup__info-value">
                            {{ info.fuel_consumption }} л. / 100 км.
                        </div>
                    </div>
                    <div class="rent-popup__info-row">
                        <div class="rent-popup__info-type">
                            Тип коробки
                        </div>
                        <div class="rent-popup__info-value">
                            {{ info.transmission }}
                        </div>
                    </div>
                    <div class="rent-popup__info-row">
                        <div class="rent-popup__info-type">
                            Цвет
                        </div>
                        <div class="rent-popup__info-value">
                            {{ info.color }}
                        </div>
                    </div>
                    <div class="rent-popup__info-row">
                        <div class="rent-popup__info-type">
                            Мощность двигателя
                        </div>
                        <div class="rent-popup__info-value">
                            {{ info.engine_capacity }}
                        </div>
                    </div>
                </div>
                <div
                    :class="[
                        'rent-popup__field __checkbox',
                        {'__readOnly': info.is_driver_required}
                    ]"
                >
                    <div class="rent-popup__field-placeholder">
                        Дополнительные услуги
                    </div>
                    <label class="rent-popup__field-checkbox-label">
                        Аренда автомобиля с водителем
                        <input type="checkbox"
                               :class="[
                                   'rent-popup__field-checkbox',
                                  {' __readOnly': info.is_driver_requested }
                               ]"
                               v-model="formData.is_driver_requested"
                        >
                        <span></span>
                    </label>
                </div>
                <div class="rent-popup__field __selected-auto">
                    <div class="rent-popup__field-placeholder">
                        Срок аренды автомобиля
                    </div>
                    <div class="rent-popup__field-value">
                        Количество дней аренды:
                    </div>
                    <div class="rent-popup__field-date-change">
                        <span
                            @click="formData.rent_period = +formData.rent_period > 1 ? +formData.rent_period - 1 : +formData.rent_period"
                            class="rent-popup__field-date-change-btn __minus"
                        >-</span>
                        <span class="rent-popup__field-date-change-value">
                            {{ formData.rent_period }}
                        </span>
                        <span
                            class="rent-popup__field-date-change-btn __plus"
                            @click="formData.rent_period = +formData.rent_period + 1"
                        >+</span>
                    </div>
                </div>
                <div class="rent-popup__field __checkbox __politics">
                    <label class="rent-popup__field-politics-label">
                        Нажимая кнопку "Оставить заявку", Вы соглашаетесь с политикой конфиденциальности
                    </label>
                </div>
                <button
                    :class="[
                            'rent-popup__submit',
                        ]"
                    @click="sendForm"
                >Оставить заявку
                </button>
            </div>
            <div class="rent-popup__popup-content"
                v-else
            >
                <div class="rent-popup__close-btn"
                     @click="closePopup"
                >
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                        <g>
                            <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512
                                512,452.922 315.076,256 		"/>
                        </g>
                    </g>
                </svg>
                </div>
                <div v-if="!formStatus"
                     class="rent-popup__popup-image"
                >
                    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/>
                        <g data-name="1" id="_1">
                            <path
                                d="M257,461.46c-114,0-206.73-92.74-206.73-206.73S143,48,257,48s206.73,92.74,206.73,206.73S371,461.46,257,461.46ZM257,78C159.55,78,80.27,157.28,80.27,254.73S159.55,431.46,257,431.46s176.73-79.28,176.73-176.73S354.45,78,257,78Z"/>
                            <path
                                d="M342.92,358a15,15,0,0,1-10.61-4.39L160.47,181.76a15,15,0,1,1,21.21-21.21L353.53,332.4A15,15,0,0,1,342.92,358Z"/>
                            <path
                                d="M171.07,358a15,15,0,0,1-10.6-25.6L332.31,160.55a15,15,0,0,1,21.22,21.21L181.68,353.61A15,15,0,0,1,171.07,358Z"/>
                        </g>
                    </svg>
                </div>
                <div v-else
                     class="rent-popup__popup-image"
                >
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="611.994px" height="611.994px" viewBox="0 0 611.994 611.994" style="enable-background:new 0 0 611.994 611.994;"
                         xml:space="preserve">
                        <g>
                            <g>
                                <g>
                                    <path d="M248.172,423.918l-89.545-89.534c-5.637-5.637-5.637-14.778,0-20.416c5.643-5.644,14.78-5.644,20.417,0l69.128,69.122
                                        l184.796-184.802c5.644-5.643,14.78-5.643,20.417,0c5.644,5.637,5.644,14.78,0,20.417L248.172,423.918z"/>
                                    <path d="M306.031,611.994v-14.438l-0.022,14.438C137.286,611.994,0.012,474.726,0,306.003C0,137.274,137.274,0,305.997,0
                                        c168.729,0,305.997,137.274,305.997,305.997C612,474.726,474.743,611.994,306.031,611.994z M305.997,28.878
                                        c-152.805,0-277.119,124.314-277.119,277.119C28.89,458.796,153.209,583.11,306.009,583.11h0.022
                                        c152.788,0,277.091-124.314,277.091-277.113C583.122,153.192,458.802,28.878,305.997,28.878z"/>
                                </g>
                            </g>
                        </g>
                        </svg>
                </div>
                {{ formMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Inputmask from 'inputmask';

export default {
    name: "RentModal",
    props: {
        status: {
            default: true
        },
        text: {},
        info: {
            year: '',
            fuel_type: '',
            side: '',
            fuel_consumption: '',
            transmission: '',
            color: '',
            engine_capacity: ''
        },
    },
    methods: {
        closePopup() {
            this.$emit('closePopupRent');
            this.successForm = false;
            document.body.style.overflow = ''
        },
        openPopup() {
            this.$emit('openPopupRent')
        },
        sendForm() {
            if (this.formData.phone === '') {
                this.formValid = false;
                this.showErrors = true;
            }
            else {
                this.formValid = true;

                this.formData.car = this.info.id;

                let token = '';
                document.cookie ? token = document.cookie.split('=')[1] : '';

                axios({
                    url: this.baseUrl + '/api/orders/',
                    method: 'post',
                    data: this.formData,
                    headers: {
                        'content-type': 'application/json',
                        'data-type': 'json',
                        'x-requested-with': 'XMLHttpRequest',
                        'X-CSRFToken': token
                    },
                })
                    .then(response => response.data)
                    .then(data => {
                        this.formStatus = true;
                        this.formData.name = '';
                        this.formData.phone = '';
                        this.showErrors = false;
                        this.formMessage = 'Спасибо за Вашу заявку, менеджер свяжется с Вами в ближайшее время.'
                    })
                    .catch((error) => {
                        this.formStatus = false;
                        this.formMessage = 'Что то пошло не так. Перезагрузите страницу и попробуйте еще раз.';
                    })
                    .finally(() =>{
                        this.successForm = true
                    })
            }
        }
    },
    data() {
        return {
            infoOpen: false,
            formData: {
                name: "",
                phone: "",
                car: 0,
                is_driver_requested: true,
                rent_period: 1
            },
            formValid: false,
            showErrors: false,
            successForm: false,
            formMessage: '',
            formStatus: false
        }
    },
    watch: {
        info: function() {
            this.formData.is_driver_requested = this.info.is_driver_required
            this.formData.car = this.info.id;
            this.successForm = false;
        },
        status: function () {
            var im = new Inputmask("+7 (999) 999-99-99");
            im.mask(document.getElementById('phoneModal'));
            this.formData = {
                    name: "",
                    phone: "",
                    car: 0,
                    is_driver_requested: true,
                    rent_period: 1
            }
            this.status ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''
        }
    },
    mounted() {
        var im = new Inputmask("+7 (999) 999-99-99");
        im.mask(document.getElementById('phoneModal'));
        console.log(process.env)
    },
    computed: {
        baseUrl: () => window.baseUrl.VUE_APP_WEBSITE_URL
    }
}
</script>

<style lang="scss" scoped>;
.rent-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;

    &.__open {
        display: block;
    }

    &__box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 750px;
        min-width: 650px;
        border-radius: 4px;
        background: #fff;
        z-index: 13;
        overflow-y: auto;
        max-height: 90vh;

        @include below($mobile) {
            min-width: 385px;
        }

        @include below(385px) {
            min-width: 300px;
        }

        &-text {
            min-width: 245px;
            height: 380px;
            margin: 40px 35px;
            overflow-y: scroll;
        }
    }

    &__content {
        padding: 24px;

        &.__success {
            font-size: 22px;
            font-weight: 500;
        }
    }

    &__close-btn {
        position: fixed;
        width: 15px;
        height: 15px;
        right: 24px;
        top: 24px;
        cursor: pointer;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000099;
        z-index: 12;
        cursor: pointer;
    }

    &__title {
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 18px;
    }

    &__description {
        margin-bottom: 12px;
        color: #8B8B8B;
        font-size: 14px;
    }

    &__input {
        width: 100%;
        padding: 16px;
        margin-bottom: 24px;
        background: #F8F8F8;
        border: none !important;
        border-radius: 0;
    }

    &__field {
        position: relative;
        &.__selected-auto,
        &.__politics {
            position: relative;
            padding: 16px 0;
            display: flex;
            flex-wrap: wrap;

            &::before {
                position: absolute;
                content: '';
                width: calc(100% + 48px);
                height: 1px;
                left: -24px;
                top: 0px;
                background: #dfdfdf75;
            }
        }
        &.__checkbox {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 16px;
        }

        &.__readOnly {
            pointer-events: none;
            opacity: .7;
        }
    }

    &__field-placeholder {
        width: 100%;
        margin-bottom: 8px;
        color: #8B8B8B;
        font-size: 14px;
    }

    &__field-value {
        width: 50%;

        @include below($mobile) {
            width: 100%;
        }
    }

    &__field-select {
        position: relative;
        width: 50%;
        padding-right: 30px;
        text-align: right;
        font-weight: 500;
        color: #212050;
        cursor: pointer;

        @include below($mobile) {
            width: 100%;
            text-align: left;
            margin-top: 10px;
        }

        &.__open {
            &:after {
                right: 0px;
                transform: translateY(50%) rotate(-135deg);
            }

            &:before {
                right: 6px;
                transform: translateY(50%) rotate(135deg);
            }
        }

        &:before,
        &:after {
            position: absolute;
            content: '';
            bottom: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 10px;
            height: 2px;
            background: #212050;
            transition: .25s ease-in;
        }

        &:after {
            right: 0px;
            transform: translateY(50%) rotate(-45deg);
        }

        &:before {
            right: 6px;
            transform: translateY(50%) rotate(45deg);
        }
    }

    &__field-checkbox {
        display: none;
    }

    &__field-politics-label {
        font-size: 12px;
    }

    &__field-checkbox-label {
        position: relative;
        padding-left: 26px;
        cursor: pointer;

        @include below($mobile) {
            font-size: 14px;
        }

        &::after {
            position: absolute;
            content: '';
            left: 0;
            bottom: 2px;
            width: 16px;
            height: 16px;
            background: #F8F8F8;
            border: 1px solid #D7D7D7;
        }

        input {
            display: none;

            &:checked ~ span {
                &::before {
                    width: 6px;
                }

                &:after {
                    width: 14px;
                }
            }
        }

        span {
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 16px;
            height: 16px;
            z-index: 2;

            &::before,
            &::after {
                width: 0px;
                position: absolute;
                content: '';
                bottom: 4px;
                right: 8px;
                transform: translateY(-50%) rotate(45deg);
                height: 2px;
                background: #212050;
                transition: .15s ease-in;
            }

            &:after {
                bottom: 6px;
                right: -3px;
                transform: translateY(-50%) rotate(135deg);
            }
        }
    }

    &__field-date-change {
        margin-left: auto;
        display: flex;
        align-items: center;

        @include below($mobile) {
            margin-top: 10px;
             margin-left: 0;
        }

        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            padding: 4px;
            background: #F8F8F8;
            cursor: pointer;
            font-weight: bold;
            user-select: none;
        }

        &-value {
            margin: 0 10px;
            font-size: 18px;
            font-weight: 500;
        }
    }

    &__submit {
        width: 100%;
        padding: 16px 0;
        background: #212050;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        opacity: 1;
        pointer-events: auto;
        border: none;

        &:hover {
            background: #28285e;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin: 0 0 10px;
        height: 100%;
        max-height: 0px;
        overflow: hidden;
        transition: max-height .65s ease-in-out,
                    margin .25s ease-in-out;

        &.__open {
            max-height: 750px;
            margin: 0 0 25px;
        }

        &-row {
            display: flex;
            justify-content: space-between;

            &:not(:first-child) {
                margin-top: 6px;
            }
        }

        &-type {
            font-weight: 400;
            font-size: 14px;
            color: #8B8B8B;
        }

        &-value {
            font-weight: 400;
            font-size: 14px;
        }
    }

    &__error {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top:-15px;
        font-size: 14px;
        color: #de5e5e
    }

    &__popup {

        &-content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 18px;
            background: #fff;
            padding: 85px 50px;
            z-index: 1;
        }

        &-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #00000099;
            z-index: 0;
        }

        &-image {
            width: 125px;
            height: 125px;
            margin-bottom: 25px;

            svg {
                width: 125px;
                height: 125px;
            }
        }
    }
}
</style>