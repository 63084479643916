<template>
    <div class="header">
        <div class="header__container container">
            <div class="header__logo">
                <img :src="icons.logo">
            </div>
            <div :class="[
                'header__nav',
                { 'open': openMenu }
            ]">
                <a
                    v-for="el in menuElements"
                    :key="el.link"
                    v-scroll-to="el.link"
                    class="header__nav-el"
                    @click="openMenu = false"
                >{{ el.name }}</a>
                <div class="header__nav-mob-contacts">
                    <a
                        class="header__nav-mob-contacts-tel"
                        :href="'tel:' + contacts.phone.number">
                        {{ getMask(contacts.phone) }}
                    </a>
                    <div class="header__nav-mob-contacts-address">
                        {{ contacts.address }}
                    </div>
                    <div class="header__nav-mob-contacts-mess">
                        <a
                            v-if="contacts.viber"
                            class="header__nav-mob-contacts-mess-el"
                            :href="contacts.viber">
                            <img :src="icons.viber" />
                        </a>
                        <a
                            v-if="contacts.telegram"
                            class="header__nav-mob-contacts-mess-el"
                            :href="contacts.telegram">
                            <img :src="icons.telegram" />
                        </a>
                        <a
                            v-if="contacts.whatsapp"
                            class="header__nav-mob-contacts-mess-el"
                            :href="contacts.whatsapp">
                            <img :src="icons.whatsapp" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="header__contacts">
                <a
                    class="header__contacts-tel"
                    :href="'tel:' + contacts.phone">
                    {{ getMask(contacts.phone) }}
                </a>
                <div class="header__contacts-btn"
                     v-scroll-to="`#feedback`"
                >
                    Заказать обратный звонок
                </div>
            </div>
            <div :class="[
                    'header__mobile-nav-btn',
                    { '__closed-ico': openMenu }
                ]"
                 @click="openMenu = !openMenu"
            >
                <img class="header__mobile-nav-btn-ico __burger"
                     :src="icons.burger" />
                <img class="header__mobile-nav-btn-ico __closed"
                     :src="icons.closed" />
            </div>
        </div>
    </div>
</template>

<script>
import telegram from '../assets/images/Telegram.svg';
import viber from '../assets/images/Viber.svg';
import whatsapp from '../assets/images/WhatsApp.svg';
import closed from '../assets/images/closed.svg';
import burger from '../assets/images/burger.svg';
import logo from '../assets/images/logo.svg';

export default {
    name: 'HeaderApp',
    data() {
        return {
            icons: {
                telegram,
                viber,
                whatsapp,
                closed,
                burger,
                logo
            },
            openMenu: false
        }
    },
    props: {
        menuElements: {
            type: Array
        },
        contacts: {
            type: Object
        }
    },
    methods: {
        getMask(number) {
            const result = '+' + number[1] + ' (' + number.slice(2, 5) + ') ' + number.slice(5, 8) + '-' + number.slice(8, 10) + '-' + number.slice(10, 12)
            return result;
        }
    },
    watch: {
        openMenu: function() {
            this.openMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''
        }
    }
}
</script>

<style lang="scss">;
.header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    padding: 20px 0;
    color: #2B2B2B;
    box-shadow: 0 1px 0 rgba(225, 225, 225, 0.25);
    z-index: 11;

    @include below($mobile) {
        padding: 20px 12px;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__logo {
        display: flex;
        align-items: center;
        width: 125px;
        font-weight: 800;
        font-size: 32px;

        @include below($mobile) {
            font-size: 16px;
        }
    }

    &__nav {
        margin-left: 150px;

        @include below($desktop) {
            margin-left: 50px;
        }
        @include below($mobile) {
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            top: 64px;
            right: 0;
            width: 100%;
            height: calc(100% - 65px);
            padding: 12px 0;
            margin: 0;
            background: #fff;
            box-shadow: 0px -1px 0px rgba(225, 225, 225, .25);
            max-width: 0px;
            overflow: hidden;
            transition: .45s ease-in-out;
            opacity: .0;

            &.open {
                max-width: 768px;
                opacity: 1;
            }
        }

        &-el {
            position: relative;
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;

            &:not(:nth-child(1)) {
                margin-left: 24px;
            }
            @include below($mobile) {
                margin: 12px 0 !important;
                font-weight: 700;
                font-size: 20px;
            }
            &:before {
                position: absolute;
                content: '';
                bottom: -5px;
                left: 0;
                width: 0%;
                height: 1px;
                background: $brandColor;
                transition: .2s ease-in-out;
            }

            &:hover:before {
                width: 100%;
            }
        }

        &-mob-contacts {
            display: none;

            @include below($mobile) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: auto;
                font-weight: 400;
                font-size: 14px;
            }

            &-address {
                margin-top: 16px;
            }

            &-mess {
                display: flex;
                margin: 20px 0 40px;

                &-el {
                    width: 32px;
                    height: 32px;

                    &:not(:first-child) {
                        margin-left: 24px;
                    }
                }
            }
        }
    }

    &__contacts {
        display: flex;
        margin-left: auto;

        @include below($desktop) {
            flex-direction: column;
        }

        &-tel {
            position: relative;
            transition: .2s ease-in-out;

            @include below($mobile) {
                display: none;
            }

            &:before {
                position: absolute;
                content: '';
                bottom: -5px;
                left: 0;
                width: 0%;
                height: 1px;
                background: $brandColor;
                transition: .2s ease-in-out;
            }
            &:hover:before {
                width: 100%;
            }
        }

        &-btn {
            margin-left: 40px;
            font-weight: bold;
            cursor: pointer;
            position: relative;
            color: $brandColor;
            transition: .2s ease-in-out;

            @include below($desktop) {
                margin-left: 0px;
                margin-top: 10px;
            }

            @include below($mobile) {
                margin-left: 18px;
                margin-right: 20px;
                margin-top: 0px;
                font-size: 14px;
            }

            @include below(420px) {
                margin-right: 0px;
            }

            &:before {
                position: absolute;
                content: '';
                bottom: -5px;
                left: 0;
                width: 0%;
                height: 1px;
                background: $brandColor;
                transition: .2s ease-in-out;
            }
            &:hover:before {
                width: 100%;
            }
        }
    }

    &__mobile-nav-btn {
        position: relative;
        display: none;
        height: 20px;

        @include below($mobile) {
            display: block;
            margin-left: 25px;
        }

        &-ico {
            position: absolute;
            top: 50%;
            left: -10px;
            transition: .2s ease-in-out;

            &.__burger {
                width: 16px;
                height: 18px;
                transform: scale(1) translateY(-50%);
            }

            &.__closed {
                width: 14px;
                height: 14px;
                transform: scale(0) translateY(-50%);
            }
        }

        &.__closed-ico {
            & .__burger {
                transform: scale(0) translateY(-50%);
            }

            & .__closed {
                transform: scale(1) translateY(-50%);
            }
        }
    }
}
</style>
