<template>
    <div class="footer">
        <div class="footer__container container">
            <div class="footer__logo">
                <img :src="icons.logo">
            </div>
            <div class="footer__nav">
                <a
                    v-for="el in menuElements"
                    class="footer__nav-el"
                    v-scroll-to="el.link"
                    :key="el.link"
                >
                    {{ el.name }}
                </a>
            </div>
            <div class="footer__contacts">
                <a :href="'tel:' + contacts.phone" class="footer__contacts-phone">
                    {{ getMask(contacts.phone) }}
                </a>
                <div class="footer__contacts-socials">
                    <a
                        v-if="contacts.viber"
                        :href="'viber://chat/?number=%2B' + contacts.viber"
                        class="footer__contacts-socials-el"
                      >
                        <img :src="icons.viber" />
                    </a>
                    <a
                        v-if="contacts.telegram"
                        :href="'tg://resolve?domain=' + contacts.telegram"
                        class="footer__contacts-socials-el"
                    >
                        <img :src="icons.telegram" />
                    </a>
                    <a
                        v-if="contacts.whatsapp"
                        :href="'https://wa.me/' + contacts.whatsapp"
                        class="footer__contacts-socials-el"
                    >
                        <img :src="icons.whatsapp" />
                    </a>
                </div>
                <div class="footer__contacts-address">
                    {{ contacts.address }}
                </div>
            </div>
            <div class="footer__politics"
                @click="openPolitics"
            >
                Политика конфидециальности
            </div>
        </div>
    </div>
</template>

<script>
import whatsapp from '../assets/images/WhatsApp.svg';
import closed from '../assets/images/closed.svg';
import burger from '../assets/images/burger.svg';
import telegram from "@/assets/images/Telegram.svg";
import viber from "@/assets/images/Viber.svg";
import logo from '../assets/images/logo.svg';

export default {
    name: "Footer",
    props: {
        menuElements: {
            type: Array
        },
        contacts: {}
    },
    data() {
        return {
            icons: {
                telegram,
                viber,
                whatsapp,
                closed,
                burger,
                logo
            },
        }
    },
    methods: {
        getMask(number) {
            const result = '+' + number[1] + ' (' + number.slice(2, 5) + ') ' + number.slice(5, 8) + '-' + number.slice(8, 10) + '-' + number.slice(10, 12)
            return result;
        },
        openPolitics() {
            this.$emit('openPopupPolitics')
        }
    }
}
</script>

<style scoped lang="scss">;
.footer {
    display: flex;

    &__container {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: 20px 0 32px;

        @include below($mobile) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__politics {
        position: absolute;
        bottom: 32px;
        left: 0;
        cursor: pointer;

        @include below($mobile) {
            width: 100%;
            text-align: center;
            left: 50%;
            bottom: 16px;
            transform: translateX(-50%);
            font-size: 14px;
            text-decoration: underline;
        }

        &:hover {
            color: #666;
        }
    }

    &__logo {
        width: 125px;
        font-weight: 800;
        font-size: 32px;

        @include below($mobile) {
            font-size: 16px;
        }
    }

    &__nav {
        display: flex;
        margin: 15px 0 auto;

        @include below($mobile) {
            flex-direction: column;
            text-align: center;
        }

        &-el {
            position: relative;
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;

            &:not(:nth-child(1)) {
                margin-left: 24px;

                @include below($mobile) {
                    margin-left: 0;
                    margin-top: 16px;
                }
            }

            &:before {
                position: absolute;
                content: '';
                bottom: -5px;
                left: 0;
                width: 0%;
                height: 1px;
                background: $brandColor;
                transition: .2s ease-in-out;
            }

            &:hover:before {
                width: 100%;
            }
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        @include below($mobile) {
            text-align: center;
            align-items: center;
            margin-top: 40px;
            padding-bottom: 36px;
        }

        &-phone {
            position: relative;
            margin-right: auto;

            @include below($mobile) {
                text-align: center;
                margin: 0;
            }

            &:before {
                position: absolute;
                content: '';
                bottom: -5px;
                left: 0;
                width: 0%;
                height: 1px;
                background: $brandColor;
                transition: .2s ease-in-out;
            }

            &:hover:before {
                width: 100%;
            }
        }

        &-socials {
            display: flex;
            margin-top: 24px;

            @include below($mobile) {
                order: 1
            }

            &-el {
                &:not(:nth-child(1)) {
                    margin-left: 24px;
                }
            }
        }

        &-address {
            font-weight: 400;
            font-size: 16px;
            margin-top: 24px;
        }
    }
}
</style>