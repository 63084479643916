import Vue from 'vue'
import App from './App.vue'
import VScrollToTop from 'v-scroll-to-top'
const VueScrollTo = require('vue-scrollto');
import VUE_APP_WEBSITE_URL from '../envConfigs.json';

const baseUrl = VUE_APP_WEBSITE_URL;

Vue.use(VScrollToTop)
Vue.use(VueScrollTo, {
  offset: -60
})

window.baseUrl = baseUrl;

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
